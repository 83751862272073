<template>
  <div class="place-page">

    <div class="buttons">
      <router-link :to="{path: '/place/map'}" class="button-back-empty">
        <span class="fa fa-arrow-left"></span>
      </router-link>
    </div>

    <div id="camera"></div>

    <div>
      <div class="title">Scan QR-code</div>
      <div class="description">
        To start exercising, scan the QR code. <br />
        You can find the code on the reception desk.
      </div>
    </div>

  </div>
</template>

<script>
import {Html5Qrcode} from "html5-qrcode"

export default {

  data: () => ({
    qrCode: null,
    isScan: false
  }),

  async mounted() {

    await this.initCamera();
  },

  methods: {

    async initCamera() {

      this.qrCode = new Html5Qrcode("camera");

      this.qrCode.start({
        facingMode: "environment"
      }, {
        fps: 4,
        qrbox: 250
      },
      async (text) => {

        if (this.isScan)
          return;

        try {

          const url = new URL(text);
          this.isScan = true;

          await this.redirect(url.pathname + url.search);

        } catch (e) {

          return;
        }

      }).catch(() => {

        this.showModal('Please allow access to the camera in your browser settings and refresh page');
      });

    },

    stopCamera() {

      if (this.qrCode?.isScanning)
        this.qrCode.stop();
    }

  },

  beforeUnmount() {

    this.stopCamera();
  }
}
</script>

<style scoped>

.place-page {
  background: #717171;
  height: 100vh;
}

.buttons {
  padding-top: 16px;
  padding-left: 15px;
}

.title {
  font-size: 28px;
  text-align: center;
  color: #FFF;
}

.description {
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  color: #FFF;
}

</style>